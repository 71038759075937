.ag-theme-alpine {
  /* disable all borders */
  --ag-borders: none;
  /* then add back a border between rows */
  --ag-borders-row: 1px solid;
}

.ag-paging-panel {
  min-height: 56px;
  border-top-color: rgb(224, 224, 224);
}

.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
  height: 100%;
}

.ag-header-cell-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.S_N9 {
  flex: 1 1 auto;
  overflow: hidden;
}

input[class^='ag-'] {
  border: 1px solid #cbc6c6 !important;
}

.ag-header-row-column-filter {
  border-bottom: 1px solid rgb(224, 224, 224);
}

.ag-header-row-column {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-top: 1px solid rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.ag-header-viewport {
  border-top: 1px solid rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);
}

.ag-header-row-column {
  border-top: none;
}

.container-accountant-parent {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 6px;
  line-height: 1.5714285714285714;
  font-size: 0.875rem;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  text-transform: capitalize;
}

.container-accountant-parent span {
  flex-basis: 50%;
  box-sizing: border-box;
  padding: 0 10px;
  line-height: 25px;
}

.container-accountant-parent:hover {
  background-color: rgba(145, 158, 171, 0.16);
}

.container-wholesale-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container-wholesale-icon svg {
  margin-bottom: 15px;
}

.main-wholesale .css-14etxj-MuiPaper-root-MuiCard-root,
.main-wholesale .css-1ikd09h-MuiPaper-root-MuiCard-root {
  background-color: #fff;
  color: #212b36;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: none;
  border: 1px solid rgba(145, 158, 171, 0.2);
  border-radius: 16px;
  z-index: 0;
  padding: 24px;
}

li.optionCT {
  display: table-row;
}

li.optionCT span {
  display: table-cell;
  padding: 4px 8px;
}

li.optionCT span:first-child {
  font-weight: bold;
  width: 150px;
}

.component-text-field-disabled .MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: #212b36;
  background: #f2f2f3;
  border-radius: 8px;
}

.component-field-disabled .Mui-disabled:not(label) {
  -webkit-text-fill-color: #212b36;
  background: #f2f2f3;
  border-radius: 8px;
}

.ag-theme-alpine .ag-row.ag-row-pinned {
  background-color: #e6e6e6;
}

#print-page-repair {
  font-family: sans-serif;
}

.MuiTable-root.css-btvdr4-MuiTable-root .MuiTableCell-root {
  border-bottom: 1px dashed rgb(241, 243, 244) !important;
}

.MuiTablePagination-root {
  border-top: none !important;
}

@media print {
  * {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.c-box__loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.c-box__loading_v2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  border-radius: 8px;
}

.c-box__loading_v3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  border-radius: 8px;
}

.c-box__loading__icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disable-screen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 76px);
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* table.MuiTable-root thead tr th{
  text-align: center !important;
} */
.inputHidden {
  display: none !important;
}

.format-css-blog img {
  width: auto;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}

.table-cell-hide {
  display: none !important;
}

.table-custom-pagination ul {
  flex-wrap: nowrap !important;
  margin-left: 10px;
}

.blog-card-content.css-1fb8tyu-MuiCardContent-root {
  padding-bottom: 0 !important;
}

.input-number-text-align-right input {
  text-align: right;
}

.scroll-bar-custom-css::-webkit-scrollbar-thumb {
  background-color: #919191;
}

.scroll-bar-custom-css::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scroll-bar-custom-css::-webkit-scrollbar-thumb {
  background-color: #919191;
  border-radius: 6px;
}

.scroll-bar-custom-css::-webkit-scrollbar-thumb {
  background-color: #919191;
}

.scroll-bar-custom-css::-webkit-scrollbar-thumb:active {
  background-color: #919191;
}

.report-card-head .MuiCardHeader-action {
  display: inline-flex;
}

.company-name {
  color: #24aae1;
}

.hide-row-table-detail-order {
  display: none !important;
}
